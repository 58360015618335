main {
    height: 100vh;
}

.chat-sessions {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 54px);
    overflow-y: scroll;
    background-color: #375b6d;
    z-index: 2000;
}

.user-menu {
    display: flex;
    flex-direction: column;
    height: 54px;
    overflow-y: visible;
    background-color: #375b6d;
    z-index: 2000;
}

.chat-session:hover {
    background-color: #426375 !important;
}

.chat-session-active {
    background-color: #4b6c7c !important;
}

.chat-messages {
    height: 100vh;
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    white-space: nowrap;
}

.chat-message {
    display: flex;
    flex-shrink: 0
}

.chat-actions:hover {
    border: 1px solid #ddd;
    color: #333;
    background-color: #eee;
    border-radius: 3px;
}

.chat-actions {
    color: #6c757d;
    background-color: transparent;
    border: 0px;
}

.logo {
    width: 200px;
}

.avatar {
    max-height: 40px;
    max-width: 60px;
}

/* Sidebar - start */

@keyframes sideBarHideAnimation {
    0% {
        transform: translateX(0);
        display: block;
    }

    /* 99% {
        transform: translateX(-100%);
        display: block;
    } */

    100% {
        transform: translateX(-100%);
        display: none;
    }
}

.sidebar-hide {
    animation: sideBarHideAnimation 0.3s forwards;
}

@keyframes sideBarShowAnimation {
    0% {
        transform: translateX(-100%);
        display: none;
    }

    /* 99% {
        transform: translateX(0);
        visibility: hidden;
    } */

    100% {
        transform: translateX(0);
        display: block;
    }
}

.sidebar-show {
    animation: sideBarShowAnimation 0.3s forwards;
}

/* 
.sidebar-hide {
    transform: translateX(-100%);
    transition: all 0.3s ease-in-out;
}

.sidebar-show {
    transform: translateX(0);
    transition: all 0.3s ease-in-out;
    
} */


.sidebar-toggler {
    z-index: 2000;
}


.transition-width {
    transition: width 0.3s ease-in-out;
}

.hidden-div {
    width: 0;
    overflow: hidden;
    transition: width 0.3s ease-in-out, padding 0.3s ease-in-out;
}

/* Sidebar - end */

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Navbar - start */
.scrolled-down {
    transform: translateY(-100%);
    transition: all 0.3s ease-in-out;
}

.scrolled-up {
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
}

.nav-link:hover {
    color: #34a0a4;
}


/* Navbar - end */

table.datatable {
    border: 1px solid #ccc;
    background-color: #fff;
}

table.datatable tr td,
table.datatable tr th {
    border: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;

}

.chat-input {
    resize: none;
    overflow-y: auto;
    height: 58px;
    min-height: 58px;
    max-height: 200px;
}


.quoted-message {
    /* Your styling for the quoted message */
    resize: none;
    overflow-y: auto;
    height: auto;
    padding: 5px;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 5px;
  }

.quoted-message-paragraph {
    resize: none;
    margin-bottom: 0; /* Remove bottom margin to prevent extra space */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    max-height: 100px;
    width: auto;
  }

.source-card {
    width: 18rem;
}

.crop {
    width: 100%;
    max-height: 200px;
    overflow: hidden;
}

.crop img {
    width: 100%;
    height: auto;
}